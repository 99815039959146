html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* TODO use chakra variables */
.p-stripe-form {
  width: 100%;
}

/* Override react-responsive-carousel */
.dot {
  width: 16px !important;
  height: 16px !important;
  background: #0078d8 !important;
  margin: 0 12px !important;
  box-shadow: none !important;
}

.carousel-slider li {
  opacity: 0.4;
}

.carousel-slider .selected {
  opacity: 1;
}
